/*
Bowl examples:
  - http://vyt.to/754hv5
  - vytal.app.link/schale?q=c176e121dd24d446db795271a5588ffbc
  - https://www.vytal.org/cc?q=c2d80e4a25d1d4321b8d1ec1e4d4e85bc
Label example: bb.bellabona.com/dispatch/A
*/

export const labelPattern = /^BB.BELLABONA.COM\/DISPATCH\/([A-Z]|[1-9][0-9]?)$/;
export const bowlPattern = /VYT\.TO|VYTAL\.APP\.LINK|WWW\.VYTAL\.ORG/;
export const PASSTHROUGH_BOWL_CODE = 'BAMBOO PACKAGING';
